import { createBrowserRouter, useRouteError } from 'react-router-dom';
import { Layout } from './layout';

// https://github.com/remix-run/react-router/discussions/10166#discussioncomment-5985748
const BubbleError = () => {
  const error = useRouteError();
  throw error;
};

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <BubbleError />,
    children: [
      {
        index: true,
        lazy: () => import('./pages/index'),
      },
      {
        path: 'add-recipe',
        lazy: () => import('./pages/add-recipe'),
      },
      {
        path: 'add-ingredients',
        lazy: () => import('./pages/add-ingredients'),
      },
      {
        path: 'ingredients',
        lazy: () => import('./pages/ingredients'),
      },
      {
        path: 'meal-plan',
        lazy: () => import('./pages/meal-plan'),
      },
      {
        path: 'recipe-sources',
        lazy: () => import('./pages/recipe-sources'),
      },
      {
        path: 'recipes-by-ingredients',
        lazy: () => import('./pages/recipes-by-ingredients'),
      },
      {
        path: 'labels',
        lazy: () => import('./pages/labels'),
      },
    ],
  },
]);
