import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Container,
  IconButton,
  Button,
  Link,
  Drawer,
  List,
  ListItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import { OrganizationSwitcher, UserButton } from '@clerk/clerk-react';

interface Pages {
  text: string;
  link: string;
}

const pages: Pages[] = [
  {
    text: 'Recipes',
    link: '/',
  },
  {
    text: 'Meal Plan',
    link: '/meal-plan',
  },
  {
    text: 'Ingredients',
    link: '/ingredients',
  },
  {
    text: 'Recipe Sources',
    link: '/recipe-sources',
  },
  {
    text: 'Labels',
    link: '/labels',
  },
  {
    text: 'Add new recipe',
    link: '/add-recipe',
  },
];

const redirectPath = '/';
const appName = import.meta.env.VITE_APP_NAME;

function ResponsiveAppBar() {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component={Link}
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {appName}
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={toggleDrawer(true)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              open={open}
              sx={{ display: { xs: 'block', md: 'none' } }}
              onClose={toggleDrawer(false)}
            >
              <Box
                sx={{ width: '200px' }}
                role="presentation"
                onClick={toggleDrawer(false)}
              >
                <List>
                  {pages.map((page) => (
                    <ListItem
                      key={page.text}
                      onClick={toggleDrawer(false)}
                      component={Link}
                      href={page.link}
                      color="inherit"
                    >
                      <Typography textAlign="center">{page.text}</Typography>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Drawer>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Link href={page.link} key={page.text}>
                <Button sx={{ my: 2, color: 'white', display: 'block' }}>
                  {page.text}
                </Button>
              </Link>
            ))}
          </Box>

          <Box sx={{ display: 'flex', marginRight: '1rem' }}>
            <OrganizationSwitcher
              afterSelectOrganizationUrl={redirectPath}
              afterSelectPersonalUrl={redirectPath}
            />
          </Box>

          <Box sx={{ display: 'flex' }}>
            <UserButton />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
